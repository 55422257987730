<template>
    <div class="ml-10 mr-10">
        <TitleBar
            :disabled="loading"
            :loading="loading"
            :menu="[
                {text: this.$t('document.download'), callback: downloadElement},
                {text: this.$t('document.process'), callback: processElement},
                {text: this.$t('document.delete'), callback: deleteElement, class: 'red--text'}
            ]"
        >{{ $t("document.view") }} <span v-if="name">({{ name }})</span>
        </TitleBar>

        <v-alert
            v-if="processed && process_status !== 'confirmed' && process_status !== 'exported'"
            prominent
            type="info"
        >
            <v-row align="center">
                <v-col class="grow">
                    {{ $t("document.review_ready") }}
                </v-col>
                <v-col class="shrink">
                    <v-btn :to="'/documents/'+$route.params.uuid+'/review'">{{ $t("document.start_review") }}</v-btn>
                </v-col>
            </v-row>
        </v-alert>

        <Tags :element="$route.params.uuid"></Tags>

        <DocumentViewer :uuid="$route.params.uuid">
            <v-btn
                :loading="loading"
                class="mt-2"
                color="secondary"
                elevation="2"
                @click.prevent="downloadElement"
            >{{ $t("document.download") }}
            </v-btn>
        </DocumentViewer>
    </div>
</template>

<script>
import Accounting from "../../helpers/Accounting";
import Tags from "../../components/Tags";
import Utils from "../../helpers/Utils";
import TitleBar from "@/components/TitleBar.vue";
import DocumentViewer from "@/components/DocumentViewer.vue";

export default {
    name: "Details",
    components: {DocumentViewer, TitleBar, Tags},
    mounted() {
        this.$store.commit("SET_BREADCRUMBS", [
            {
                text: this.$t("menu.home"),
                to: "/",
                exact: true
            },
            {
                text: this.$t("menu.documents"),
                to: "/documents",
                exact: true
            },
            {
                text: this.$t("document.view"),
                to: "/documents/" + this.$route.params.uuid,
                exact: true
            }
        ]);

        this.loading = true;
        Accounting.get("/documents/" + this.$route.params.uuid)
            .then(response => {
                const data = response.data;
                this.name = data.name;
                this.mimetype = data.mimetype;
                this.processed = data.processed;
                this.process_status = data.process_status;

                this.loading = false;
            }).catch(error => {
            if (error.response) {
                let errors = [];
                for (const errorElement of error.response.data.errors) {
                    errors.push({
                        type: "error",
                        text: errorElement
                    });
                }
                this.$store.commit("SET_ALERTS", errors);
            } else if (error.request) {
                this.$store.commit("SET_ALERTS", [{
                    type: "error",
                    text: this.$t("api.no_response")
                }]);
            } else {
                this.$store.commit("SET_ALERTS", [{
                    type: "error",
                    text: error.message
                }]);
            }
            this.loading = false;
        });

    },
    data: () => {
        return {
            loading: false,
            name: null,
            mimetype: null,
            object: null,
            processed: false,
            process_status: null
        };
    },
    methods: {
        deleteElement() {
            this.$swal({
                title: this.$t("document.delete_title"),
                text: this.$t("document.delete_text"),
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: this.$t("document.delete_yes"),
                cancelButtonText: this.$t("document.delete_no"),
                confirmButtonColor: Utils.getColor(this, "error"),
                cancelButtonColor: Utils.getColor(this, "primary")
            }).then(result => {
                if (result.isConfirmed) {
                    this.loading = true;
                    this.$store.commit("SET_ALERTS", []);
                    Accounting.delete("/documents/" + this.$route.params.uuid)
                        .then(() => {
                            this.$store.commit("SET_ALERTS", [{
                                type: "success",
                                text: this.$t("document.deleted")
                            }]);
                            this.loading = false;
                            this.$router.push("/documents");
                        })
                        .catch(error => {
                            if (error.response) {
                                let errors = [];
                                for (const errorElement of error.response.data.errors) {
                                    errors.push({
                                        type: "error",
                                        text: errorElement
                                    });
                                }
                                this.$store.commit("SET_ALERTS", errors);
                            } else if (error.request) {
                                this.$store.commit("SET_ALERTS", [{
                                    type: "error",
                                    text: this.$t("api.no_response")
                                }]);
                            } else {
                                this.$store.commit("SET_ALERTS", [{
                                    type: "error",
                                    text: error.message
                                }]);
                            }
                            this.loading = false;
                        });
                }
            });
        },
        downloadElement() {
            this.loading = true;
            Accounting.download("/documents/" + this.$route.params.uuid + "/view", this.name)
                .finally(() => {
                    this.loading = false;
                });
        },
        processElement() {
            this.loading = true;
            Accounting.get("/documents/" + this.$route.params.uuid + "/process")
                .then(() => {
                    this.$store.commit("SET_ALERTS", [{
                        type: "success",
                        text: this.$t("document.sent_processing")
                    }]);
                })
                .catch(() => {
                    this.$store.commit("SET_ALERTS", [{
                        type: "error",
                        text: this.$t("document.already_processed")
                    }]);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        formSubmit() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.loading = true;

                let url = "/documents";

                if (this.$route.params.uuid !== "new") {
                    url = url + "/" + this.$route.params.uuid;
                }

                this.$store.commit("SET_ALERTS", []);

                Accounting.post(url, {
                    currency: this.currency,
                    name: this.name,
                    bank: this.bank
                }).then(() => {

                    this.loading = false;

                    this.$store.commit("SET_ALERTS", [{
                        type: "success",
                        text: this.$t("accounts.updated")
                    }]);


                }).catch(error => {
                    if (error.response) {
                        let errors = [];
                        for (const errorElement of error.response.data.errors) {
                            errors.push({
                                type: "error",
                                text: errorElement
                            });
                        }
                        this.$store.commit("SET_ALERTS", errors);
                    } else if (error.request) {
                        this.$store.commit("SET_ALERTS", [{
                            type: "error",
                            text: this.$t("api.no_response")
                        }]);
                    } else {
                        this.$store.commit("SET_ALERTS", [{
                            type: "error",
                            text: error.message
                        }]);
                    }
                    this.loading = false;
                });

            }
        }
    }
};
</script>

